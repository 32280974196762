import React, { useState } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import PageBody from './PageBody'
import ArrowBoxComponent from "./ArrowBox";
import styled from 'styled-components'

const StyledContainer = styled.div`
`

const StyledImageWrapper = styled.div`
	position: relative;
	width: 100%;
	
	.gatsby-image-wrapper {
		padding-top: 75%;
	}
`

const StyledWrapper = styled.div`
`

const ArrowContainer = styled.div`
	margin: 1rem auto;
`

const ContentGridContainer = styled.div`
`

const ContentGridWrapper = styled.div`
	display: flex;
	width: 100%;
	padding-top: 1rem;
		
	@media screen and (max-width: 750px) {
		display: block;
	}
	
	> * {
		width: 50%;
		display: flex;
	
		&:first-child {
			padding-right: 1.5rem;
			* {
				-webkit-hyphens: none;
			  -ms-hyphens: none;
			  hyphens: none;
      }
		}
		
		@media screen and (max-width: 750px) {
			width: 100%;
			display: block;
		}
		
	}

	&:not(.is-active) { 
		display: none;
	}
`

export default function WieFunktioniertGrid(props) {
	const [isActive, setIsActive] = useState(0)

	const changeContent = number => {
		setIsActive(number)
		console.log(setIsActive)
	}

	const mappedTitles = props.data.map((el, i) => (
		<StyledWrapper
			key={`wie-funktioniert-title-${el.id}`}
			onClick={() => changeContent(i)}
		>
			<ArrowBoxComponent
				className={`fourth centered light-grey-background grey ${isActive === i ? 'is-active' : ''}`}
			>
				{ el.title }
			</ArrowBoxComponent>
		</StyledWrapper>
	))

	const mappedContent = props.data.map((el, i) => (
		<ContentGridWrapper
			key={`wie-funktioniert-el-${el.id}`}
			className={`${isActive === i ? 'is-active' : ''}`}
		>
			<PageBody
				body={ el.text }
				strong={'green'}
			/>
			<br />
			<StyledImageWrapper>
				<Img
					fluid={ el.image.fluid }
					style={{
						top: 0,
						left: 0,
						height: 0,
						width: '100%'
					}}
					imgStyle={{
						position: 'absolute',
						height: '100%',
						width: '100%',
						top: 0,
						left: 0
					}}
				/>
			</StyledImageWrapper>
		</ContentGridWrapper>
	))

	return (
		<StyledContainer>
			<ArrowContainer>
				{ mappedTitles }
			</ArrowContainer>
			<ContentGridContainer>
				{ mappedContent }
			</ContentGridContainer>
		</StyledContainer>
	)
}