import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledWrapper = styled.div`
  h3 {
    font-size: 1.2em;
    color: ${props => props.theme.colors.secondary} !important;
    padding: 0.6rem 0.4rem 0.4rem;
    font-weight: 800;
    margin: 0;
  }
  p {
    margin: 0;
    padding: 0;
    display: block !important;
    width: 100%;
    text-align: center;
  }
  .price {
    margin: 0;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 1.4em;
    font-weight: 800;
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.inverted};
  }
`

const StyledDiv = styled.div`
  background: ${props => props.theme.colors.tertiary};
  text-align: left;
  padding: 0.25rem;
  margin: 0 auto 1.25rem auto;
  background: white;
  min-width: 201px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  a {
    color: ${props => props.theme.colors.base};
  }
  h3 {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 0.9rem !important;
    text-align: center;
  }
  .price-logo {
    text-align: left;
    margin: 0 auto;
  }
`

const Testimonial = props => {
  return (
    <StyledWrapper>
      <StyledDiv>
        <Img
          className="price-logo"
          fluid={props.data.node.image.fluid}
          style={{height: 0, paddingTop: '100%'}}
          imgStyle={{ position: 'absolute', top: 0, left: 0 }}
        />
        <h3>{props.data.node.title}</h3>
      </StyledDiv>
      <p>{props.data.node.text}</p>
      <p className="price">{props.data.node.price}{' '}Fr.</p>
    </StyledWrapper>
  )
}

export default Testimonial
