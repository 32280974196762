import React from 'react'
import styled from 'styled-components'

const TriangleTop = styled.div`
  position: absolute;
  top: -24px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-right: 24px solid white;
  
  &.grey {
    border-top: 24px solid ${props => props.theme.colors.secondary};
    background: ${props => props.theme.colors.secondary} !important;
    
    &.is-active {
      border-top: 24px solid ${props => props.theme.colors.highlight};
      background: ${props => props.theme.colors.highlight} !important;
    }
  }
  
  &.green {
    border-top: 24px solid ${props => props.theme.colors.highlight};
    // background: ${props => props.theme.colors.highlight} !important;
  }
  
  &.green-background {
    border-right: 24px solid ${props => props.theme.colors.highlight};
  }
  
  &.light-grey-background {
    border-right: 24px solid ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.lightGrey} !important;
    // border-right-color: ${props => props.theme.colors.lightGrey} !important;
  }
`

const TriangleBottom = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  top: 24px;
  right: 0;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  &:not(.grey) { border-right: 24px solid white; }
  &:not(.light-grey) { border-right: 24px solid white; }
  background: ${props => props.theme.colors.secondary};
  
  &.fourth.is-active {
    background: ${props => props.theme.colors.highlight};
  }
  &.green {
    border-top: 24px solid ${props => props.theme.colors.highlight};
  }
  &:hover {
    // border-top: 24px solid ${props => props.theme.colors.secondary};
  }
  &.green-background {
    border-right: 24px solid ${props => props.theme.colors.highlight};
  }
  &.light-grey-background {
    border-right: 24px solid ${props => props.theme.colors.lightGrey}  !important;
    color: ${props => props.theme.colors.lightGrey} !important;
    &:hover {
      color: ${props => props.theme.colors.lightGrey} !important;
    }
  }
`

const StyledDiv = styled.div`
  background: ${props => props.theme.colors.secondary};
  color: white;
  position: relative;
  float: none;
  margin: 1rem auto;
  padding: 1rem;
  overflow: hidden;
  &.grey:not(.is-active) {
    background: ${props => props.theme.colors.secondary} !important;
  }
  // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  font-family: ${props => props.theme.fonts.title};
  font-weight: 800;

  &.third {
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      float: left;
      width: 32%;
      margin-left: 1%;
      margin-right: 1%;
      &:first-child {
        margin-right: 1%;
        margin-left: 0;
      }
      &:nth-child(3) {
        margin-left: 1%;
        margin-right: 0;
      }
    }
    &:hover {
      color: white;
      background: ${props => props.theme.colors.highlight};
    }
  }
  
  &.fourth {
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      float: left;
      width: 24%;
      text-align: center;
      margin-left: 1%;
      margin-right: 1%;
      padding-left: 0;
      &:first-child {
        margin-right: 1%;
        margin-left: 0;
      }
      &:nth-child(3) {
        margin-left: 1%;
        margin-right: 0;
      }
    }
    &:hover {
      color: white;
      background: ${props => props.theme.colors.highlight};
      cursor: pointer;
    }
  }

  &.float {
    float: left;
  }
  &.relaxed {
    margin: 2rem auto;
  }
  &.centered {
    text-align: center;
  }
  &.green {
    background: ${props => props.theme.colors.highlight};
    &:hover {
      background: ${props => props.theme.colors.secondary};
      * { border-top-color: ${props => props.theme.colors.secondary}; }s
    }
    a,
    span,
    p {
      color: ${props => props.theme.colors.inverted};
    }
  }
  &.grey {
    background: ${props => props.theme.colors.secondary};
    &.is-active {
      background: ${props => props.theme.colors.highlight};
    }
    color: ${props => props.theme.colors.inverted} !important;
    &:hover, &:hover * {
      background: ${props => props.theme.colors.highlight} !important;
      color: ${props => props.theme.colors.secondary} !important;
    }
    a,
    span,
    p {
      color: ${props => props.theme.colors.inverted};
    }
  }
`

const ArrowBoxComponent = props => {
  return (
    <StyledDiv className={props.className}>
      {props.children}
      <TriangleTop className={props.className} />
      <TriangleBottom className={props.className} />
    </StyledDiv>
  )
}

export default ArrowBoxComponent
