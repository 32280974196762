import React, { useState, useEffect } from 'react'
import Carousel from '@brainhubeu/react-carousel'
import styled from 'styled-components'
import Price from './Price'
import '../styles/slider.scss'

const StyledDiv = styled.div`
  position: relative;
  .BrainhubCarousel {
    display: grid;
    overflow-y: visible;
    padding: 2rem 0 2rem 0;
    h1,
    h2,
    h3 {
      font-size: 1.1em;
    }
    p {
      font-size: 1em;
    }
    .BrainhubCarousel__trackContainer {
      overflow-y: visible;
    }}
  }
`

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent !important;
  pointer-events: none;
  z-index: 1;
  background-image: linear-gradient(to right, rgba(238,238,238,0.9), rgba(238,238,238,0), rgba(238,238,238,0), rgba(238,238,238,0), rgba(238,238,238,0), rgba(238,238,238,0), rgba(238,238,238,0.9)) !important;
}
`

const SliderKunden = props => {
  const [shuffledArray, setShuffledArray] = useState(props.data.edges)

  const shuffle = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  useEffect(() => {
    setShuffledArray(shuffle(props.data.edges))
  })

  const mappedPrices = shuffle(shuffledArray)
    .map((el, i) => <Price key={`price-item-${i}`} data={el} />)

  return (
    <StyledDiv>
      {props.gradientOverlay &&
        <GradientOverlay className={`${props.gradientOverlay}`} />
      }
      <Carousel
        // autoPlay={4000}
        animationSpeed={250}
        infinite
        centered={props.centered}
        stopAutoPlayOnHover
        slidesPerPage={props.slidesPerPage || 2}
        slidesPerScroll={1}
        breakpoints={{
          950: { // these props will be applied when screen width is less than 1000px
            slidesPerPage: props.slidesPerPage || 2
          },
          800: { // these props will be applied when screen width is less than 1000px
            slidesPerPage: props.slidesPerPage || 3
          },
          650: { // these props will be applied when screen width is less than 1000px
            slidesPerPage: 1
          },
          321: {
            slidesPerPage: 1
          },
        }}
      >
        {mappedPrices}
      </Carousel>
    </StyledDiv>
  )
}

export default SliderKunden
