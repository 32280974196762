import React, { useState, useRef } from 'react'
import Modal from 'react-modal';
import styled from 'styled-components'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/icon.min.css'

const StyledVideosWrapper = styled.div`
  display: flex;
  width: 25%;
  margin: .5rem;
  align-items: space-between;
  
  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 0;
  }
`

const StyledVideo = styled.div`
	position: relative;
	width: 100%;
	margin-top: 1.5rem;
	display: flex;

	*:focus {
		&:focus {
			outline: none !important;
		}
	}

	video {
		width: 100%;
		&:focus {
			outline: none !important;
		}
	}
	
	p {
		color: ${props => props.theme.colors.secondary};
		line-height: 1.15;
		-webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    margin-top: 0.9rem;
	}
	
	&:hover { 
		cursor: pointer;
		/*button { 
			background: ${props => props.theme.colors.secondary} !important; 
			color: ${props => props.theme.colors.highlight}; 
		}*/
	}
	
	@media screen and (max-width: 500px) {
		display: block;
		margin: 0;
	}
`

const PlayButton = styled.button`
	position: absolute;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
	margin: 0 !important;
	background: ${props => props.theme.colors.highlight} !important;
	pointer-events: none;
	
	i { margin: 0 !important; }
`

export default function VideoGrid(props) {
	const [lightBox, setLightBox] = useState({
		open: false,
		slide: 0,
	})

	const openModal = number => {
		setLightBox({
			slide: number,
			open: !lightBox.open,
		})
	}

	const closeModal = () =>{
		setLightBox({
			open: false,
			slide: lightBox.slide
		})
	}

	const mappedVideos = props.data.map((el, i) => (
		<StyledVideosWrapper className={'column'} key={`video-${el.video.id}`}>
			<div
				style={{position: 'relative'}}
				onClick={(e) => {
					openModal(i)
				}}
			>
				<video poster={ el.placeholder.file.url }>
					<source src={ el.video.file.url } type={'video/mp4'} />
				</video>
				<PlayButton
					className={'ui circular big icon button'}
				>
					<i className={'icon play'} />
				</PlayButton>
			</div>
			<p>{ el.video.description }</p>
		</StyledVideosWrapper>
	))

	const customStyles = {
		overlay: {
			// background: '#C2CF00'
		},
		content : {
			top                   : '50%',
			left                  : '50%',
			right                 : 'auto',
			bottom                : 'auto',
			marginRight           : '-50%',
			transform             : 'translate(-50%, -50%)',
			position: 'fixed',
			borderRadius: 0,
			fontFamily: 'Roboto, sans-serif',
			width: '75vw'
		}
	};


	const afterOpenModal = () => {
		// references are now sync'd and can be accessed.
		// title.style.color = '#ff0000'
		// console.log('opened modal box')
	}

	return (
		<StyledVideo>
			{ mappedVideos }
			<Modal
				shouldFocusAfterRender={false}
				isOpen={ lightBox.open }
				onAfterOpen={ afterOpenModal }
				onRequestClose={ closeModal }
				style={ customStyles }
				contentLabel={ props.title }
			>

				<button
					className={'ui circular basic icon button close-button'}
					onClick={closeModal}
					style={{
						position: 'absolute !important',
						right: '8px !iomportant',
						marginRight: '5px',
						top: '-5px !important',
					}}
				>
					<i className={'red icon remove'} />
				</button>
				<h1 style={{fontFamily: 'Aller, sans-serif', fontSize: '1.5rem', marginBottom: '0.5rem', paddingRight: '3rem'}}>{ props.title }</h1>
				<p style={{fontFamily: 'Aller, sans-serif', marginBottom: '1.5rem', paddingRight: '3rem'}}>{ props.data[lightBox.slide].description}</p>
				{ lightBox.open &&
				<div>
					<video
						// poster={ props.data[lightBox.slide].placeholder.file.url }
						preload
						controls
						autoPlay
						style={{
							width: '100%'
						}}
					>
						<source src={ props.data[lightBox.slide].video.file.url || '' } type={'video/mp4'} />
					</video>
				</div>
				}

			</Modal>
		</StyledVideo>
	)
}
