import React from 'react'
import { Link, graphql } from 'gatsby'
import config from '../utils/siteConfig'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import RibbonComponent from '../components/Ribbon'
import FlexBoxComponent from '../components/FlexBox'
import GridComponent from '../components/Grid'
import ArrowBoxComponent from '../components/ArrowBox'
import SliderComponent from '../components/Slider.Preise'
import VideoGrid from '../components/VideoGrid'
import SEO from '../components/SEO'
import TextContainer from '../components/TextContainer'
import PageTitle from '../components/PageTitle'
import WieFunktioniertGrid from '../components/WieFunktioniertGrid'

const StyledHeader = styled.h3`
  margin-bottom: 0;
  @media screen and (max-width: 500px) {
    font-size: 1.4em !important;
    margin-bottom: 2rem;
  }
  
  &.videos-title {
    @media screen and (min-width: 500px) {
      margin-bottom: 0;
    }
  }
`

const StyledAdvantagesContainer = styled.div`
  * {
    color: ${props => props.theme.colors.secondary};
  }
`

const StyledVideosContainer = styled.div`
  * {
    color: ${props => props.theme.colors.secondary} !important;
  }
`


const StyledProductsContainer = styled.div`
  margin-left: 8rem;
  
  * {
    color: ${props => props.theme.colors.secondary};
  }
  
  @media screen and (max-width: 800px) {
    margin: auto;
  }
`

const HandwerkSubpage = ({ data, pageContext }) => {
  const postNode = {
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  const {
    body,
    title,
    subtitle,
    advantages,
    headerImage,
    products,
    video,
    videosTitle,
    wieFunktioniertTitle,
    wieFunktioniertElements,
  } = data.contentfulPage

  const mappedHandwerk = data.allContentfulPage.edges.sort((a,b) => a.Reihenfolge - b.Reihenfolge).map((el, i) => (
    <Link key={`mapped-handwerk-${i}`} to={'/' + el.node.slug.replace('-', '/')}>
      <ArrowBoxComponent
        className={'third centered grey light-grey-background'}>{el.node.title}
      </ArrowBoxComponent>
    </Link>
  ))

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <PageTitle background={headerImage} />

      <Container relaxed>
        <TextContainer>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </TextContainer>
        {body && <PageBody body={body} />}
      </Container>

      <FlexBoxComponent background="light-grey" borderBottom borderTop>
        <RibbonComponent
          text="Jetzt zum Preisrechner"
          link="/preise"
          image={data.contentfulPage.ribbon}
          color="green"
        />
        <TextContainer>
          <GridComponent>
            <div className="row">
              <div className="column">
                <div className="weitere-handwerke">
                  <StyledHeader
                    style={{
                      marginBottom: 0,
                      zIndex: 555
                    }}
                  >
                    Richtpreise
                  </StyledHeader>
                </div>
                <SliderComponent
                  // gradientOverlay={'white'}
                  data={data.allContentfulRichtpreise}
                  slidesPerPage={4}
                  // centered
                />
              </div>
            </div>
          </GridComponent>
        </TextContainer>
      </FlexBoxComponent>

      <FlexBoxComponent backgroundLeft={"green"} backgroundRight={"grey"} borderBottom>
        <TextContainer>
          <GridComponent>
            <div className="row">
              {advantages && (
                <StyledAdvantagesContainer className="column advantages-column">
                  <PageBody body={advantages} className="left-aligned grey-text" />
                </StyledAdvantagesContainer>
              )}

              {products && (
                <StyledProductsContainer className="grey column">
                  <PageBody body={products} className="left-aligned" />
                </StyledProductsContainer>
              )}
            </div>
          </GridComponent>
        </TextContainer>
      </FlexBoxComponent>

      <FlexBoxComponent background="light-grey" borderBottom>
        <GridComponent>
          <div className="row">
            <div className="column">
              <div className="weitere-handwerke">
                <div className="weitere-handwerke">
                  <StyledHeader>{ wieFunktioniertTitle }</StyledHeader>
                  <WieFunktioniertGrid data={ wieFunktioniertElements } />
                </div>
              </div>
            </div>
          </div>
        </GridComponent>
      </FlexBoxComponent>

      <FlexBoxComponent background={"green"} borderBottom>
        <TextContainer>
          <GridComponent>
            <div className="row">
              {videosTitle && (
                <StyledVideosContainer className="column">
                  <div className="weitere-handwerke">
                    <StyledHeader className={'videos-title'}>{ videosTitle }</StyledHeader>
                  </div>
                </StyledVideosContainer>
              )}
            </div>
            <div className="row">
              {video && (
                <VideoGrid
                  title={ videosTitle }
                  data={ video }
                />
              )}
            </div>
          </GridComponent>
        </TextContainer>
      </FlexBoxComponent>

      <FlexBoxComponent background="light-grey">
        <GridComponent>
          <div className="row">
            <div className="column">
              <div className="weitere-handwerke">
                <StyledHeader
                  style={{marginBottom: '1rem'}}
                >
                  Weitere Handwerke
                </StyledHeader>
                { mappedHandwerk }
              </div>
            </div>
          </div>
        </GridComponent>
      </FlexBoxComponent>

    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allContentfulRichtpreise(filter: { category: { in: [$slug] } }) {
      edges {
        node {
          id
          title
          text
          image {
            fluid(quality: 100, maxWidth: 3840, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
            description
          }
          slug
          price
        }
      }
    }
    allContentfulPage(sort: {fields: order, order: ASC}, filter: {category: {eq: "Handwerk"}, slug: {nin: [$slug]}}) {
      edges {
        node {
          id
          category
          subtitle
          title
          video {
            id
            video {
                file {
                  url
                }
            }
          }
          slug
          headerImage {
            id
            fluid(quality: 100, maxWidth: 1920, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
              base64
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              aspectRatio
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      subtitle
      ribbon {
        fluid(maxWidth: 400) {
          base64
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      wieFunktioniertTitle
      wieFunktioniertElements {
        id
        image {
          fixed(cropFocus: CENTER, height: 720, width: 1280) {
            base64
            aspectRatio
            srcWebp
            srcSetWebp
          }
          fluid(
            maxWidth: 600,
            maxHeight: 480
          ) {
            base64
            srcWebp
            srcSetWebp
            srcSet
            src
            sizes
            aspectRatio
            ...GatsbyContentfulFluid
          }
          title
        }
        title
        text {
          json
        }
      }
      headerImage {
        id
        fluid(quality: 100, maxWidth: 3840, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
          base64
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      previewImage {
        id
        fluid(quality: 100, maxWidth: 3840, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
          base64
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      videosTitle
      video {
        placeholder {
          file {
            url
          }
        }
        video {
          id
          description
          file {
            url
          }
        }
      }
      body {
        json
      }
      advantages {
        json
      }
      products {
        json
      }
    }
  }
`

export default HandwerkSubpage