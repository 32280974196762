import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  width: 100%;
  position: relative;
  * {
    font-family: ${props => props.theme.fonts.text};
  }
  &.green * {
    color: ${props => props.theme.colors.inverted};
  }
  .light-grey * {
    color: ${props => props.theme.colors.secondary};
  }
  .grey {
    * {
      color: ${props => props.theme.colors.inverted};
      
      @media screen and (max-width: 800px) {
        color: ${props => props.theme.colors.secondary};
      }
    }
    background: ${props => props.theme.colors.secondary};
    @media screen and (max-width: 800px) {
      background: transparent;
      color: ${props => props.theme.colors.secondary};
    }
  }
  h1,
  h2,
  h3 {
    font-family: ${props => props.theme.fonts.title};
    font-weight: 800;
  }
  ul {
    margin-bottom: 0;
  }
  ul li {
    p {
      display: inline;
      margin: 0;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-basis: 100%;
    .left-aligned h3, .weitere-handwerke h3 {
      font-size: 1.8em;
    }
    p {
      font-size: 1.1em;
    }
    .weitere-handwerke {
      @media screen and (max-width: ${props => props.theme.responsive.small}) {
        // padding-top: 2rem;
      }
    }
  }
  .double-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }
  .padded {
    padding: 0.5rem 1rem;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    .column {
      flex: 1;
    }
    .double-column {
      flex: 2;
    }
  }
`

const GridComponent = props => {
  return (
    <StyledDiv
      className={`${props.background}`}
    >
      {props.children}
     </StyledDiv>
  )
}

export default GridComponent
